exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agenda-online-tsx": () => import("./../../../src/pages/agenda-online.tsx" /* webpackChunkName: "component---src-pages-agenda-online-tsx" */),
  "component---src-pages-assinatura-eletronica-tsx": () => import("./../../../src/pages/assinatura-eletronica.tsx" /* webpackChunkName: "component---src-pages-assinatura-eletronica-tsx" */),
  "component---src-pages-cadastro-especialista-tsx": () => import("./../../../src/pages/cadastro-especialista.tsx" /* webpackChunkName: "component---src-pages-cadastro-especialista-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../../../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-clinica-de-estetica-tsx": () => import("./../../../src/pages/clinica-de-estetica.tsx" /* webpackChunkName: "component---src-pages-clinica-de-estetica-tsx" */),
  "component---src-pages-gestao-e-financeiro-tsx": () => import("./../../../src/pages/gestao-e-financeiro.tsx" /* webpackChunkName: "component---src-pages-gestao-e-financeiro-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integracao-alexa-tsx": () => import("./../../../src/pages/integracao-alexa.tsx" /* webpackChunkName: "component---src-pages-integracao-alexa-tsx" */),
  "component---src-pages-lancamentos-tsx": () => import("./../../../src/pages/lancamentos.tsx" /* webpackChunkName: "component---src-pages-lancamentos-tsx" */),
  "component---src-pages-marketing-e-vendas-tsx": () => import("./../../../src/pages/marketing-e-vendas.tsx" /* webpackChunkName: "component---src-pages-marketing-e-vendas-tsx" */),
  "component---src-pages-meu-doutor-tsx": () => import("./../../../src/pages/meu-doutor.tsx" /* webpackChunkName: "component---src-pages-meu-doutor-tsx" */),
  "component---src-pages-migracao-de-dados-tsx": () => import("./../../../src/pages/migracao-de-dados.tsx" /* webpackChunkName: "component---src-pages-migracao-de-dados-tsx" */),
  "component---src-pages-planos-e-precos-tsx": () => import("./../../../src/pages/planos-e-precos.tsx" /* webpackChunkName: "component---src-pages-planos-e-precos-tsx" */),
  "component---src-pages-politica-tsx": () => import("./../../../src/pages/politica.tsx" /* webpackChunkName: "component---src-pages-politica-tsx" */),
  "component---src-pages-prontuario-digital-tsx": () => import("./../../../src/pages/prontuario-digital.tsx" /* webpackChunkName: "component---src-pages-prontuario-digital-tsx" */),
  "component---src-pages-redes-e-franquias-agradecimento-tsx": () => import("./../../../src/pages/redes-e-franquias-agradecimento.tsx" /* webpackChunkName: "component---src-pages-redes-e-franquias-agradecimento-tsx" */),
  "component---src-pages-redes-e-franquias-tsx": () => import("./../../../src/pages/redes-e-franquias.tsx" /* webpackChunkName: "component---src-pages-redes-e-franquias-tsx" */),
  "component---src-pages-termos-index-tsx": () => import("./../../../src/pages/termos/index.tsx" /* webpackChunkName: "component---src-pages-termos-index-tsx" */),
  "component---src-pages-termos-meudoutor-tsx": () => import("./../../../src/pages/termos/meudoutor.tsx" /* webpackChunkName: "component---src-pages-termos-meudoutor-tsx" */)
}

