import { pageViewEvent } from '@/constants/analytics';

const SITE_EVENT = 'Eventos do Site';

export interface ButtonClickMetadata {
  event: string;
  buttonName: string;
}

export interface PageViewMetadata {
  pagePath: string;
}

export interface EventMetadata {
  category: string;
  action: string;
  label?: string;
}

export type CustomEventMetadata = { [key: string]: any } & { event: string };

const push = (data: { [key: string]: any }) => window.dataLayer?.push(data);

export const trackButtonClick = ({ event, buttonName }: ButtonClickMetadata) => {
  if (!event || !buttonName) {
    return;
  }

  push({ event, buttonName });
};

export const trackPageView = ({ pagePath }: PageViewMetadata) => {
  if (!pagePath) {
    return;
  }

  push({ event: pageViewEvent, pagePath });
};

export const trackCustomEvent = ({ event, ...rest }: CustomEventMetadata) => {
  if (!event) {
    return;
  }

  push({ event, ...rest });
};

export const trackEvent = ({ category, action, label }: EventMetadata) => {
  if (!category || !action) {
    return;
  }

  push({
    event: SITE_EVENT,
    category,
    label: label || action,
    action,
  });
};
