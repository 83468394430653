export const buttonEvents = {
  menu: 'buttonMenu',
  footer: 'buttonFooter',
  home: 'buttonHome',
  lancamentos: 'buttonLancamentos',
  marketingVendas: 'buttonMarketingVendas',
  gestaoFinanceiro: 'buttonGestaoFinanceiro',
  prontuario: 'buttonProntuario',
  agendaOnline: 'buttonAgendaOnline',
  precosEPlanos: 'buttonPrecosEPlanos',
  assinaturaEletronica: 'buttonAssinaturaEletronica',
  integracaoAlexa: 'buttonIntegracaoAlexa',
  meuDoutor: 'buttonMeuDoutor',
  migracaoDados: 'buttonMigracao',
  redesEFranquias: 'buttonRedesEFranquias',
};

export const pageViewEvent = 'virtualPageView';

export const menuButtonNames = {
  testeGratis: 'testeGratisMenu',
};

export const footerButtonNames = {
  testarGratis: 'testarGratisFooter',
  testeGratisLink: 'testeGratisFooterLink',
};

export const homeButtonNames = {
  testarGratisHero: 'testarGratisHero',
  falarEspecialistaHero: 'falarEspecialistaHero',
  saibaMaisGestaoFinancas: 'saibaMaisGestaoFinancas',
  saibaMaisProntuarioDigital: 'saibaMaisProntuarioDigital',
  saibaMaisAgendaOnline: 'saibaMaisAgendaOnline',
  saibaMaisNovidades: 'saibaMaisNovidades',
  testarGratisDuvidas: 'testarGratisDuvidas',
};

export const precosEPlanosButtonNames = {
  testarGratisCardAgenda: 'testarGratisCardAgenda',
  testarGratisCardAgendaAnual: 'testarGratisCardAgendaAnual',
  testarGratisCardGestao: 'testarGratisCardGestao',
  testarGratisCardGestaoAnual: 'testarGratisCardGestaoAnual',
  testarGratisCardVendas: 'testarGratisCardVendas',
  testarGratisCardVendasAnual: 'testarGratisCardVendasAnual',
  testarGratisCardMarketing: 'testarGratisCardMarketing',
  compararPlanosActions: 'compararPlanosActions',
  testarGratisActions: 'testarGratisActions',
  falarEspecialistaFaleConosco: 'falarEspecialistaFaleConosco',
  saibaMaisRedes: 'saibaMaisRedes',
};

export const funcionalidadesButtonNames = {
  testarGratisHero: 'testarGratisHero',
  falarEspecialistaHero: 'falarEspecialistaHero',
  conhecaNossosPlanos: 'conhecaNossosPlanos',
  conhecaMaisAgendaOnline: 'conhecaMaisAgendaOnline',
  conhecaMaisProntuarioDigital: 'conhecaMaisProntuarioDigital',
  conhecaMaisGestaoFinancas: 'conhecaMaisGestaoFinancas',
  conhecaMaisMarketingVendas: 'conhecaMaisMarketingVendas',
  conhecaMaisAssinaturaEletronica: 'conhecaMaisAssinaturaEletronica',
};

export const lancamentosButtonNames = {
  saibaMaisAssinaturaEletronicaContrato: 'saibaMaisAssinaturaEletronicaContrato',
};

export const migracaoDadosButtonNames = {
  testarGratisHero: 'testarGratisHero',
  falarEspecialistaHero: 'falarEspecialistaHero',
  falarEspecialistaMigracao: 'falarEspecialistaMigracao',
  testarGratisCard: 'testarGratisCard',
  falarEspecialistaFooter: 'falarEspecialistaFooter',
  testarGratisFaq: 'testarGratisFaq',
  falarEspecialistaFaq: 'falarEspecialistaFaq',
  testarGratisFeatures: 'testarGratisFeatures',
  testarGratisMigracao: 'testarGratisMigracao',
};

export const redesEFranquiasButtonNames = {
  redesEFranquiasHero: 'redesEFranquiasHero',
  redesEFranquiasComoFunciona: 'redesEFranquiasComoFunciona',
  redesEFranquiasBlocoFeatures: 'redesEFranquiasBlocoFeatures',
  redesEFranquias100SimplesDental: 'redesEFranquias100SimplesDental',
  redesEFranquiasDepoimento: 'redesEFranquiasDepoimento',
  redesEFranquiasBoxPreco: 'redesEFranquiasBoxPreco',
  redesEFranquiasFormFinal: 'redesEFranquiasFormFinal',
};

export const integracaoAlexaButtonNames = {
  testarGratisHero: 'testarGratisHero',
  testarGratisFeatures: 'testarGratisFeatures',
};

export const categories = {
  clinicaEstetica: 'Clínica de Estética',
  migracaoDados: 'Migração de Dados',
};

export const clinicaEsteticaActions = {
  heroTestar: 'Hero - Testar grátis por 7 dias',
  heroFalarEspecialista: 'Hero - Falar com especialista',
  benefitsTestar: 'Cards de Vantagens - Quero testar',
  cardsSimplificamosTestar: 'Cards de Simplificamos a sua rotina - Quero testar',
  earnMoreTestar: 'Ganhe mais - Quero testar',
  detailsHofTestar: 'Detalhes - HOF - Quero testar',
  detailsOrcamentoTestar: 'Detalhes - Orçamento - Quero testar',
  detailsAssinaturaEletronicaTestar: 'Detalhes - Assinatura Eletrônica - Quero testar',
  detailsCrmVendasTestar: 'Detalhes - CRM Vendas - Quero testar',
  footerFalarEspecialista:
    'Footer - Simplicidade com o máximo de excelência - Falar com especialista',
};

export const migracaoDadosActions = {
  cardsSimplificamosTestar: 'Cards de Simplificamos a sua rotina - Quero testar',
};
